import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Container from "../components/container";
import {
  FaLock,
  FaCheckSquare,
  FaExclamationTriangle,
  FaUsers,
  FaEye,
  FaInfo
} from "react-icons/fa";
import styles from "./bezpieczenstwo-danych.module.css";
import SEO from "../components/seo";

const BezpieczenstwoDanych = () => {
  return (
    <Layout>
      <SEO
        title="Bezpieczeństwo danych w systemie CRM"
        description="System CRM, który zapewnia całkowite bezpieczeństwo Twoich danych • Certyfikat SSL, monitoring bezpieczeństwa systemu, zintegrowany mechanizm uprawnień dostępu do danych, zaawansowany system uwierzytelniania oraz pełna poufność danych firmowych • Wypróbuj QuickCRM i zacznij sprzedawać więcej!"
      />
      <Hero maxHeight="280px" justifyContent="center">
        <h1>Bezpieczeństwo</h1>
        <p>Jak chronimy ważne dla Ciebie informacje?</p>
      </Hero>
      <Container>
        <div className={styles.securityList}>
          <p>
            Wiemy, jak ważne&nbsp;jest bezpieczeństwo, dlatego podchodzimy do
            niego ze szczególną uwagą.{" "}
            <strong>
              Gwarantujemy Ci pełne bezpieczeństwo danych, które umieszczasz
              w&nbsp;systemie
            </strong>
            . Korzystamy ze znanych sposobów ochrony danych tj. szyfrowane
            połączenia SSL, ale również wykorzystujemy własne, sprawdzone
            rozwiązania, które pozwalają nam na ciągłe monitorowanie poprawności
            działania systemu.
          </p>
          <ul>
            <li>
              <div>
                <FaLock size="40px" />
              </div>
              <div>
                <p>
                  <strong>
                    Stosujemy szyfrowane połączenia SSL, aby zapewnić tajność
                    informacji
                  </strong>
                </p>
                <p>
                  Certyfikat chroni Twoją witrynę, na której znajduje się
                  system. Gwarantuje bezpieczeństwo przesyłanych danych.
                </p>
              </div>
            </li>
            <li>
              <div>
                <FaCheckSquare size="40px" />
              </div>
              <div>
                <p>
                  <strong>
                    Zintegrowany mechanizm uprawnień dostępu do danych
                  </strong>
                </p>
                <p>
                  W systemie dostępny jest mechanizm uprawnień. Polega to na
                  nadawaniu dostępu do różnych obszarów w systemie, z podziałem
                  na profile. Możesz tworzyć profile administratorów,
                  kierowników czy handlowców, nadając im wybrane uprawnienia.
                </p>
              </div>
            </li>
            <li>
              <div>
                <FaExclamationTriangle size="40px" />
              </div>
              <div>
                <p>
                  <strong>
                    Blokujemy dostęp do systemu po kilkukrotnym podaniu błędnego
                    hasła
                  </strong>
                </p>
                <p>
                  Dla systemu kilkukrotne błędne wpisanie hasła to informacja,
                  że ktoś trzeci może chcieć uzyskać dostęp do Twojego systemu.
                  W takim przypadku hasło zostaje zablokowane.
                </p>
              </div>
            </li>
            <li>
              <div>
                <FaUsers size="40px" />
              </div>
              <div>
                <p>
                  <strong>
                    Stosujemy zaawansowany mechanizm uwierzytelniania
                    użytkowników
                  </strong>
                </p>
                <p>
                  System wymusza utworzenie hasła, które będzie silnym
                  zabezpieczeniem (zawierającym odpowiednią ilość znaków, znaki
                  specjalne itp.).
                </p>
              </div>
            </li>
            <li>
              <div>
                <FaEye size="40px" />
              </div>
              <div>
                <p>
                  <strong>
                    Wprowadziliśmy monitoring poprawności działania systemu
                  </strong>
                </p>
                <p>
                  Dbamy o bezpieczeństwo na każdym etapie. Zabezpieczamy systemy
                  naszych klientów oraz monitorujemy ich poprawność działania.
                  Nasz własny monitoring pozwala na szybkie wykrycie problemów z
                  dostępem do Twojej domeny. Monitoring poprawności działania
                  systemu minimalizuje ryzyko pojawienia się problemów z
                  działaniem systemu do zera.
                </p>
              </div>
            </li>
            <li>
              <div>
                <FaInfo size="40px" />
              </div>
              <div>
                <p>
                  <strong>
                    Gwarantujemy pełną poufność informacji umieszczanych w
                    systemie
                  </strong>
                </p>
                <p>
                  Wszystkie informacje naszych klientów są bezpieczne. Tylko
                  wybrani pracownicy naszej firmy mają dostęp do systemu
                  klienta. Jest on niezbędny przy wykonywaniu aktualizacji czy
                  rozwiązywaniu problemów zgłaszanych przez klienta. Nasi
                  pracownicy mają obowiązek zachowania poufności informacji.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </Container>
    </Layout>
  );
};

export default BezpieczenstwoDanych;
